.i18n-en {
  &.page-home .pane-recipes-homepage .slideshow-controls-buttons .slideshow-prev {
    margin-left: -3.6em;
  }

  &.page-home .pane-recipes-homepage .slideshow-controls-buttons .slideshow-next {
    margin-left: 3.6em;
  }
}

.i18n-fr {
  &.page-home .pane-recipes-homepage .slideshow-controls-buttons .slideshow-prev {
    margin-left: -5em;
  }

  &.page-home .pane-recipes-homepage .slideshow-controls-buttons .slideshow-next {
    margin-left: 5em;
  }
}
