// =================================== COLORS
$color-red: #ab1014;
$color-brown: #534741;
$color-light-gray: #ebebeb;
$color-gray-1: #bcbec0;
$color-gray-2: #808285;


// =================================== TEXT
$modular-scale-ratio: $golden;
$modular-scale-base: 1em;

// =================================== LAYOUT
$l-site-width: 1600px;
$l-content-width: 1200px;

$l-bp-site-width: new-breakpoint(max-width $l-site-width);
$l-bp-desktop: new-breakpoint(max-width 1280px);
$l-bp-tablet-landscape: new-breakpoint(max-width 1024px);
$l-bp-tablet: new-breakpoint(max-width 768px);
$l-bp-mobile: new-breakpoint(max-width 480px);
$l-bp-mobile-small: new-breakpoint(max-width 360px);
