// =================================== FORMS
fieldset {
  position: relative;
  display: block;
  margin: 0;
  border: 0;
  padding-top: 60px;
  margin-bottom: 0.75em;

  legend {
    display: block;
    position: absolute;
    top: 10px;
    left: 10px;

    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.5em;
    font-family: BrandonGrotesque Medium,sans-serif;
  }
}

.form-item {
  margin-bottom: 0.75em;

  .description {
    margin: 0.25em;
    font-size: 0.8em;
  }
}

.form-type-checkbox {
  margin-bottom: 0.5em;

  input {
    margin-right: 0.25em;
  }
}

input.form-text,
input.form-email,
input.form-password,
select, textarea {
  box-sizing: border-box;
  padding: 8px 15px;
  font-size: inherit;
  width: 100%;
  transition: all 0.2s ease-in-out;
  border: 1px solid;
  outline: none;
  -webkit-appearance: none;
  border-radius: 5px;
  box-shadow: inset 0px 11px 8px -10px rgba(black, 0.20), inset 0px -11px 8px -10px rgba(black, 0.10);

  &:focus {

  }
}

select {
  -moz-appearance: none;
  border: 1px solid;
  width: 100%;
  display: block;
  background: white url('../images/arrow-down.svg') 98% 50% no-repeat;
  background-size: 13px auto;

  &::-ms-expand {
    display: none;
  }
}

// =================================== BREADCRUMB
.breadcrumb {
  margin: 20px 0;
  text-transform: uppercase;
  font-size: 0.8em;
  font-family: "BrandonGrotesque Medium";

  a {
    text-decoration: none;
  }

  .delimiter {
    display: inline-block;
    padding: 0 0.25em;
    vertical-align: top;
  }
}


// =================================== BUTTONS
.button, button {
  @include button($color-red);

  &.button-ghost {
    @include button($color-red, true);
  }
}

.button-filter {
  &:after {
    display: none;
  }

  &:before {
    @include font-awesome($fa-var-filter);
    margin-right: 0.5em;
  }
}

.button-block {
  display: block !important;
  text-transform: uppercase;
  text-align: center;
  padding: 15px;
  margin-bottom: 10px;
}

// =================================== NAVIGATION
.navigation-button {
  cursor: pointer;

  .navigation-burger {
    @include burger(30px, 5px, 3px, $color-brown);
  }

  &.open {
    .navigation-burger {
      @include burger-to-cross();
    }
  }
}

.l-mobile {
  position: fixed;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  left: -100%;
  height: 100%;
  min-width: 60%;
  max-width: 100%;

  background-color: white;

  border-right: 1px solid;

  .menu {
    @include list-clear;

    font-size: 16px;

    li.expanded {
      position: relative;

      > .menu {
        display: none;
      }

      > a {
        &:after {
          @include font-awesome("\f107");
          position: absolute;
          right: 1em;
          top: 1.10em;
          font-size: 1.5em;

          transition: all 1s;
        }
      }

      &.open {
        > .menu {
          display: block;
        }

        > a {
          &:after {
            transform: rotate(-180deg);
          }
        }
      }

      > .menu {
        a {
          &:before {
            content: " ";
            margin-left: 0.5em;
            margin-right: 0.5em;
          }
        }
      }
    }

    a {
      display: block;
      padding: 1.5em;
      text-decoration: none;
      text-transform: uppercase;
      font-size: 0.8em;
      font-family: "BrandonGrotesque Medium";

      background: linear-gradient(to top, #ededed 0%,#ffffff 100%);
    }
  }
}

.block--locale-language {
  position: relative;
  font-size: 0.9em;

  ul {
    @include list-clear();
    position: absolute;
    z-index: 50;
    margin-top: 20px;

    border: 1px solid $color-gray-1;

    background: white;

    transition: 500ms all;

    li {
      display: none;

      a {
        display: block;
        padding: 10px 30px 10px 15px;

        text-decoration: none;
        text-transform: uppercase;
        color: $color-gray-1;

        &:hover {
          color: $color-red;
        }
      }
    }

    li:first-child {
      display: block;

      a {
        &:after {
          @include font-awesome($fa-var-angle-down);
          display: inline-block;

          position: absolute;
          right: 0.85em;
          top: 0.85em;

          margin-left: 0.5em;

          transition: 500ms transform;
        }
      }
    }

    &.open {
      box-shadow: 0px 0px 10px rgba(black, 0.5);
      border: 0;

      li {
        display: block;

        &:first-child {
          a {
            &:after {
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }
}


// =================================== MESSAGES
.messages-wrapper {
  display: none;
}

.messages {
  margin: 0;
  border: 0;
  padding: 10px 0;
  .messages-inner {
    @include content-width();
  }
  background-image: none !important;

  &.status {
    background-color: #27ae60;
    color: white;

    a {
      color: white;
    }
  }

  &.error {
    background-color: #e74c3c;
    color: white;

    a {
      color: white;
    }
  }

  &.warning {
    background-color: #f39c12;
    color: white;

    a {
      color: white;
    }
  }

  .close {
    &:after {
      @include font-awesome($fa-var-times);
    }

    float: right;
    font-size: 1.1em;
    cursor: pointer;
  }
}

.krumo-root {
  &, a {
    color: black !important;
  }
}

// =================================== SLIDESHOW
.l-top .slideshow-wrapper {
  position: relative;

  &:hover {
    .slideshow-controls {
      .slideshow-controls-buttons {
        opacity: 1;
      }
    }
  }

  .slideshow-controls {
    position: absolute;
    z-index: 300;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .slideshow-controls-buttons {
      @include content-width;
      position: relative;
      height: 100%;

      opacity: 0.5;

      transition: 0.5s all;

      .slideshow-prev, .slideshow-next {
        position: absolute;
        left: 10px;
        top: 50%;
        width: 1em;
        height: 1em;
        overflow: hidden;
        margin-top: -0.5em;

        font-size: 2.25em;

        &:before {
          @include font-awesome($fa-var-chevron-left);
        }
      }

      .slideshow-next {
        right: 10px;
        left: auto;
        &:before {
          @include font-awesome($fa-var-chevron-right);
        }
      }
    }

    .slideshow-pager {
      display: none;
    }
  }
}

// =================================== MEDIA
.iframe-container, .media-youtube-video, .media-vimeo-video {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 35px;
  height: 0;
  overflow: hidden;

  iframe {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}


// =================================== HEADER
.bean-header, .node--header {
  position: relative;
  min-height: 300px;
  width: 100%;

  overflow: hidden;

  .image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-size: cover;
    background-position: center center;
  }

  video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;

    transform: translate(-50%, -50%);
  }

  .mobile-fallback {
    display: none;
  }

  @include media($l-bp-tablet) {
    video {
      display: none;
    }

    .mobile-fallback {
      display: block;
    }
  }
}

// =================================== MENUS
.block--menu-menu-footer .menu {
  @include list-clear;
  @include list-inline-dashed;

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.block--system-main-menu .menu {
  @include list-clear;
  @include list-inline;
  @include row;

  text-align: right;

  a {
    display: block;
    text-decoration: none;
    padding: 30px 12px;
  }

  @include media($l-bp-tablet-landscape) {
    text-align: center;
  }
}

.block--views-cheeses-dropdown {
  display: none;
  overflow: hidden;
  position: absolute;
  top: 4.5em;
  left: 25%;
  width: 600px;
  z-index: 100;

  transition: 500ms;

  .menu-dropdown > ul {
    @include list-clear;
    @include row();

    padding: 1em;

    > li {
      @include span-columns(4);
      text-align: center;
      padding: 0.5em;

      a {
        text-decoration: none;
      }
    }
  }

  @include media($l-bp-tablet-landscape) {
    top: 10em;
  }
}

.menu-link-dropdown a {
  &:after {
    @include font-awesome($fa-var-angle-down);
    display: inline-block;
    margin-left: 0.5em;

    transition: 500ms all;
  }

  &.open:after {
    transform: rotate(180deg);
  }
}

// =================================== HOMEPAGE
.page-home {
  .pane-bean-welcome {
    .bean-title {
      font-size: 2.25em;

      .first-word {
        display: block;
      }

      .word:nth-child(2) {
        font-size: 0.7em;
        vertical-align: top;
      }
    }

    .field--name-field-block-body {
      font-size: 1.25em;
      font-style: italic;
      letter-spacing: 1px;
      line-height: 1.5;
    }

    @include media($l-bp-tablet) {
      .group-block-right {
        display: none;
      }
    }
  }

  .pane-recipes-homepage {
    .slideshow-controls-buttons {
      font-size: 1.5em;
      .slideshow-prev {
        &:after {
          @include font-awesome($fa-var-angle-left);
        }
      }

      .slideshow-next {
        &:after {
          @include font-awesome($fa-var-angle-right);
        }
      }
    }
  }

  .pane-newsletter {
    padding: 2em 0;
    form {
      .label {
        text-transform: uppercase;
        font-family: "BrandonGrotesque Medium";
        font-size: 0.85em;
      }
    }
  }
}

// =================================== PAGE
.page-title {
  @include title-line();
}

// =================================== CHEESE
.node-type-cheese {
  .pane-node-field-cheese-quote {
    position: relative;
    margin: 1.75em 0;
    padding: 1em;
    padding-right: 2em;

    font-family: 'BrandonGrotesque Medium';
    text-transform: uppercase;

    &:after {
      content: '”';
      font-size: 13em;
      position: absolute;
      right: -0.05em;
      bottom: -0.95em;
    }
  }

  .pane-cheese-health {
    border: 2px solid;
    padding: 1.5em;
    margin-top: 1em;

    > .pane-title {
      font-size: 1em;
      margin: 0;

      span {
        font-family: 'BrandonGrotesque Medium' !important;
        text-transform: uppercase !important;
        font-size: 1em;
      }
    }
  }

  .pane-recipes-recipe-cheese {
    margin-top: 3em;
    position: relative;

    > .pane-title {
      @include title-line();
    }

    .view-header {
      position: absolute;
      top: 0;
      right: 0;

      span {
        display: inline-block;
        padding: 1.45em;
        background-color: white;
        font-family: 'BrandonGrotesque Medium' !important;
      }
    }
  }
}

// =================================== RECIPES
.star {
  &.star_0:after { @include font-awesome($fa-var-star-o + $fa-var-star-o + $fa-var-star-o + $fa-var-star-o + $fa-var-star-o); }
  &.star_0_5:after { @include font-awesome($fa-var-star-half-o + $fa-var-star-o + $fa-var-star-o + $fa-var-star-o + $fa-var-star-o); }
  &.star_1:after { @include font-awesome($fa-var-star + $fa-var-star-o + $fa-var-star-o + $fa-var-star-o + $fa-var-star-o); }
  &.star_1_5:after { @include font-awesome($fa-var-star + $fa-var-star-half-o + $fa-var-star-o + $fa-var-star-o + $fa-var-star-o); }
  &.star_2:after { @include font-awesome($fa-var-star + $fa-var-star + $fa-var-star-o + $fa-var-star-o + $fa-var-star-o); }
  &.star_2_5:after { @include font-awesome($fa-var-star + $fa-var-star + $fa-var-star-half-o + $fa-var-star-o + $fa-var-star-o); }
  &.star_3:after { @include font-awesome($fa-var-star + $fa-var-star + $fa-var-star + $fa-var-star-o + $fa-var-star-o); }
  &.star_3_5:after { @include font-awesome($fa-var-star + $fa-var-star + $fa-var-star + $fa-var-star-half-o + $fa-var-star-o); }
  &.star_4:after { @include font-awesome($fa-var-star + $fa-var-star + $fa-var-star + $fa-var-star + $fa-var-star-o); }
  &.star_4_5:after { @include font-awesome($fa-var-star + $fa-var-star + $fa-var-star + $fa-var-star + $fa-var-star-half-o); }
  &.star_5:after { @include font-awesome($fa-var-star + $fa-var-star + $fa-var-star + $fa-var-star + $fa-var-star); }
}

.node--recipe {
  .field--name-field-recipe-media {
    img {
      display: block;
      width: 100%;
    }
  }

  .recipe-details {
    border-bottom: 1px solid;
    padding: 10px 0;
    margin-bottom: 1em;

    .recipe-difficulty {
      float: right;

      .star {
        float: right;
        margin-left: 0.5em;
      }
    }
  }

  .recipe-ingredients {
    ul {
      @include list-clear;

      li {
        position: relative;
        padding-left: 1em;
        padding-top: 0.25em;
        padding-bottom: 0.25em;

        &:before {
          content: "\2022";
          position: absolute;
          left: 0;
        }
      }
    }
  }

  .recipe-instructions {
    counter-reset: steps;

    ul {
      @include list-clear;

      li {
        position: relative;
        padding-left: 2em;

        &:before {
          counter-increment: steps;
          content: counter(steps) ".";

          position: absolute;
          left: 0;
        }
      }
    }
  }
}

ul.recipes, ul.recipes-carousel {
  .recipe {
    .recipe-preview {
      overflow: hidden;

      img {
        display: block;
        width: 100%;

        transition: 250ms all;
      }
    }

    .recipe-title {
      a {
        display: block;
        height: 6em;
        padding: 15px;

        font-size: 0.85em;
        font-family: "BrandonGrotesque Medium";
        text-transform: uppercase;
        text-decoration: none;
      }
    }

    &:hover {
      .recipe-preview {
        img {
          backface-visibility: hidden;
          transform: translateZ(0) scale(1.025);
        }
      }
    }
  }
}

ul.recipes-carousel {

  .recipe {
    position: relative;
    padding: 10px;

    .recipe-title {
      display: none;
    }
  }
}

.recipe-filter {
  position: absolute;
  z-index: 300;
  top: 3em;
  right: -1em;
  padding: 1em;
  width: 40%;

  .recipe-filter-button {
    position: absolute;
    right: 1em;
    top: 1em;
    display: inline-block;

    padding: 0 0 0 1em;

    .button {
      display: block;
      text-align: center;
    }
  }

  .recipe-filter-form {
    display: none;
    padding: 2.5em;
    padding-top: 4em;

    label {
      display: block;
      margin-bottom: 0.25em;
      font-family: "BrandonGrotesque Medium";
      text-transform: uppercase;
    }

    .views-exposed-widget {
      margin-bottom: 1.5em;
    }


    .form-item-allergens {
      @include row();

      > label {
        display: block;
        float: left;
        width: 50%;
      }
    }

    .stars {
      display: inline-block;
      font-size: 2em;
    }
  }

  &.open {
    background-color: white;
    //border: 1px solid;

    .button-filter {
      border: 2px solid;

      &:before {
        @include font-awesome($fa-var-close);
      }
    }

    .recipe-filter-form {
      display: block;
      text-align: left;
    }
  }

  @include media($l-bp-tablet) {
    &.open {
      width: 60%;
      right: 0.5em;
    }
  }

  @include media($l-bp-mobile) {

    position: static;
    padding: 0;
    width: 100%;
    text-align: right;
    margin-bottom: 1em;


    .recipe-filter-button {
      position: static;
      text-align: right;
      padding: 0;
    }

    &.open {
      position: fixed;
      width: auto;
      right: 0.5em;
      left: 0.5em;
      top: 0.5em;
      bottom: 0.5em;
      padding: 1em;

      .recipe-filter-button {
        position: absolute;

        padding: 0 0 0 1em;
      }
    }
  }
}

.recipe-filter-overlay {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

// =================================== NEWS
.view-news.view-display-id-page {
  .views-row {
    border-bottom: 1px solid;

    .post-date {
      font-size: 1.15em;
      text-transform: uppercase;
    }

    h2 {
      font-size: 1.25em;

      &, span {
        font-family: "BrandonGrotesque Regular";
        font-weight: normal;
        text-transform: uppercase;
      }

      span {
        margin: 0;
        display: inline !important;
        font-size: inherit;
      }

      a {
        text-decoration: none;
      }
    }

    .views-field-field-news-categories {
      padding: 1em 0;

      font-family: "BrandonGrotesque Medium";
      font-size: 0.9em;
      text-transform: uppercase;
    }

    .views-field-field-news-image {
      margin-bottom: 1em;

      .field-content {
        box-shadow: 0px 0px 5px rgba(black, 0.30);
        padding: 5px;

        img {
          display: block;
        }
      }
    }

    .views-field-field-news-teaser {
      margin-bottom: 1em;
    }

    .views-field-view-node {
      a {
        @include button($color-red);
      }
    }

    &:last-child {
      border-bottom: 0;
    }
  }
}

.pane-views-exp-news-page {
  label {
    display: block;
    padding: 0.25em 0;
    margin-bottom: 0.5em;

    border-bottom-width: 3px;
    border-bottom-style: double;

    font-family: "BrandonGrotesque Medium";
    font-size: 1em;
    text-transform: uppercase;
  }

  .form-type-bef-link {
    a {
      display: block;
      padding: 0.5em 0;
      border-bottom: 1px solid;

      font-family: "BrandonGrotesque Medium";
      text-transform: uppercase;
      text-decoration: none;
      font-size: 0.9em;

      &.active {
        font-family: "BrandonGrotesque Bold";
      }
    }

    &:last-child {
      a {
        border-bottom: 0;
      }
    }
  }

  .views-submit-button {
    display: none;
  }
}

.node-type-news {
  .pane-node-created, .pane-node-field-news-categories {
    float: left;
    text-transform: uppercase;
    font-family: "BrandonGrotesque Bold";
    font-size: 0.9em;
  }

  .pane-node-created {
    &:after {
      content : "|";
      display: inline-block;
      margin: 0 0.25em;
    }
  }

  .pane-node-field-news-categories {
    .field-item {
      display: inline-block;

      &:after {
        content : "/";
        display: inline-block;
        margin: 0 0.25em;
      }

      &:last-child:after {
        content: "";
      }
    }
  }

  .pane-node-field-news-body {
    clear: both;
    padding-top: 1em;
  }

  .pane-news-recent {
    h2.pane-title {
      margin: 0;
    }

    ul {
      @include list-clear();

      li {
        border-bottom: 1px solid;
        padding: 0.75em 0;

        &:last-child {
          border: 0;
        }
      }

      a {
        text-decoration: none;
      }
    }
  }
}

// =================================== CONTACT
.page-contact {
  form {
    padding: 1em;
  }
}

// =================================== FOR PROFESSIONALS
.view-id-cheeses.view-display-id-professional_list {
  .item-list > ul {
    @include list-clear();
  }

  .cheese-packages {
    @include list-clear();
    @include list-inline();

    margin-bottom: 15px;

    > li {
      cursor: pointer;
    }

    > li > span {
      display: block;
      padding: 7px 20px;
      margin-left: 5px;
      margin-bottom: 5px;

      border: 1px solid black;
    }

    > li.active, > li:hover {
      > span {
        background-color: black;
        color: white;
      }
    }
  }

  .field--name-title {
    margin-bottom: 0.75em;
    text-transform: uppercase;
    font-family: "BrandonGrotesque Bold";
    font-size: 1.2em;

    a {
      text-decoration: none;
    }
  }

  .field--name-field-cheese-package-title {
    margin: 10px 0 5px 0;
    text-transform: uppercase;
    font-family: "BrandonGrotesque Bold";
    font-size: 0.95em;
  }

  .field--name-field-cheese-professional-intro {
    margin-bottom: 20px;
  }

  .paragraphs-items-field-cheese-packages {
    margin: 25px 0;
  }

  .field-buttons {
    margin-top: 0.75em;

    > ul {
      @include list-clear;
      @include list-inline;
    }

    .button, .button-ghost {
      margin-right: 0.5em;

      border: 3px solid #ab1014;

      text-transform: uppercase;
      font-family: "BrandonGrotesque Medium";
      font-size: 0.75em;

      &:after {
        content: "";
        margin: 0;
      }
    }
  }

  @include media($l-bp-tablet) {
    .field-buttons {
      > ul > li {
        display: block;
      }

      .button, .button-ghost {
        display: block;
        margin-bottom: 0.5em;
        text-align: center;
      }
    }
  }
}

.nazareth-pro-information-form {
  .products-sample-information {
    .product {
      .field-title {
        margin-bottom: 0.75em;
        text-transform: uppercase;
        font-family: "BrandonGrotesque Medium";
        font-size: 1.2em;
      }
    }
  }

  .form {
    padding: 1em;
  }
}

.block--menu-menu-professional {
  ul {
    @include list-clear();
    @include list-inline-dashed();
    font-size: 1.1em;
  }

  a {
    text-decoration: none;

    &.active {
      font-family: "BrandonGrotesque Bold";
    }
  }
}

// =================================== BLOCK
.bean-block {
  .field--name-field-block-link {
    margin-top: 2em;
  }
}
