// =================================== LAYOUT
@mixin site-width {
  max-width: $l-site-width;
  width: 100%;
  margin: 0 auto;
}

@mixin content-width {
  max-width: $l-content-width;
  width: 100%;
  margin: 0 auto;
  padding-left: flex-gutter();
  padding-right: flex-gutter();
}

@mixin element-invisible {
  position: absolute;
  top: 0px;
  left: 0;
  height: 0px;
  width: 0px;
  overflow: hidden;
}

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

// =================================== BUTTONS
@mixin button($color, $ghost:null) {
  display: inline-block;
  padding: 10px;
  border: 0;

  background: $color;
  color: white;

  font-size: 0.85em;
  text-decoration: none;
  text-transform: uppercase;

  font-family: "BrandonGrotesque Medium";

  @if $ghost {
    background: transparent;
    border: 3px solid $color;
    color: $color;
  }

  &:after {
    @include font-awesome($fa-var-long-arrow-right);
    margin-left: 0.5em;
  }
}

// =================================== TEXT
@mixin font-awesome($icon) {
  @include fa-icon();
  content: $icon;
}


@mixin special-title {
  font-family: "BrandonGrotesque Medium", sans-serif;
  font-weight: normal;
  text-transform: uppercase;

  .first-word {
    display: inline-block;
    margin-right: 0.2em;

    font-family: "Great Vibes";
    font-size: 2em;
    text-transform: capitalize;

    color: $color-red;
  }
}

@mixin title-line {
  position: relative;

  > span {
    display: inline-block;
    background-color: white;
    padding-right: 0.5em;
  }

  &:after {
    content: "";
    z-index: -1;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -1px;
    border-bottom: 3px solid $color-red;
  }
}

// =================================== LISTS
@mixin list-clear() {
  list-style: none;
  padding: 0;
  margin: 0;

  li, .leaf {
    list-style: none;
    padding: 0;
    margin: 0;
  }
}

@mixin list-inline {
  li, .leaf {
    display: inline-block;
  }
}

@mixin list-inline-dashed() {
  @include list-inline;

  li, .leaf {
    &:after {
      content: " | ";
      vertical-align: top;
    }

    &:last-child:after {
      content: "";
    }
  }
}

// =================================== BOURBON
@mixin row-table() {
  display: table;
  width: 100%;
}

@mixin omega-reset($nth) {
  &:nth-child(#{$nth}) { margin-right: flex-gutter(); }
  &:nth-child(#{$nth}+1) { clear: none }
}

// =================================== BURGER
@mixin burger($width: 30px, $height: 5px, $gutter: 3px, $color: #000, $border-radius: 0, $transition-duration: .3s) {
    $burger-height: $height !global;
    $burger-gutter: $gutter !global;

    position: relative;
    margin-top: $height + $gutter;
    margin-bottom: $height + $gutter;

    &, &:before, &:after {
        display: block;
        width: $width;
        height: $height;
        background-color: $color;
        @if $border-radius != 0 {
            border-radius: $border-radius;
        }

        -webkit-transition-property: background-color, -webkit-transform;
        -moz-transition-property: background-color, -moz-transform;
        -o-transition-property: background-color, -o-transform;
        transition-property: background-color, transform;

        -webkit-transition-duration: $transition-duration;
        -moz-transition-duration: $transition-duration;
        -o-transition-duration: $transition-duration;
        transition-duration: $transition-duration;
    }

    &:before, &:after {
        position: absolute;
        content: "";
    }

    &:before {
        top: -($height + $gutter);
    }

    &:after {
        top: $height + $gutter;
    }
}


// Select parts of the burger
@mixin burger-parts {
    &, &:before, &:after {
        @content;
    }
}

@mixin burger-top {
    &:before {
        @content;
    }
}

@mixin burger-middle {
    & {
        @content;
    }
}

@mixin burger-bottom {
    &:after {
        @content;
    }
}


// Burger animations
@mixin burger-to-cross {
    & {
        background-color: transparent;
    }
    &:before {
        -webkit-transform: translateY($burger-gutter + $burger-height) rotate(45deg);
        -moz-transform: translateY($burger-gutter + $burger-height) rotate(45deg);
        -ms-transform: translateY($burger-gutter + $burger-height) rotate(45deg);
        -o-transform: translateY($burger-gutter + $burger-height) rotate(45deg);
        transform: translateY($burger-gutter + $burger-height) rotate(45deg);
    }
    &:after {
        -webkit-transform: translateY(-($burger-gutter + $burger-height)) rotate(-45deg);
        -moz-transform: translateY(-($burger-gutter + $burger-height)) rotate(-45deg);
        -ms-transform: translateY(-($burger-gutter + $burger-height)) rotate(-45deg);
        -o-transform: translateY(-($burger-gutter + $burger-height)) rotate(-45deg);
        transform: translateY(-($burger-gutter + $burger-height)) rotate(-45deg);
    }
}

