// =================================== FONTS
@import url(https://fonts.googleapis.com/css?family=Great+Vibes);

@font-face{
  font-family: 'BrandonGrotesque Bold';
  src: url('../fonts/brandon-grotesque/brandongrotesque-bold-webfont.eot');
  src: url('../fonts/brandon-grotesque/brandongrotesque-bold-webfont.eot?#iefix') format('embedded-opentype'),
       url('../fonts/brandon-grotesque/brandongrotesque-bold-webfont.woff') format('woff'),
       url('../fonts/brandon-grotesque/brandongrotesque-bold-webfont.ttf') format('truetype'),
       url('../fonts/brandon-grotesque/brandongrotesque-bold-webfont.svg#webfont') format('svg');
}

@font-face{
  font-family: 'BrandonGrotesque Medium';
  src: url('../fonts/brandon-grotesque/brandongrotesque-medium-webfont.eot');
  src: url('../fonts/brandon-grotesque/brandongrotesque-medium-webfont.eot?#iefix') format('embedded-opentype'),
       url('../fonts/brandon-grotesque/brandongrotesque-medium-webfont.woff') format('woff'),
       url('../fonts/brandon-grotesque/brandongrotesque-medium-webfont.ttf') format('truetype'),
       url('../fonts/brandon-grotesque/brandongrotesque-medium-webfont.svg#webfont') format('svg');
}

@font-face{
  font-family: 'BrandonGrotesque Regular';
  src: url('../fonts/brandon-grotesque/brandongrotesque-regular-webfont.eot');
  src: url('../fonts/brandon-grotesque/brandongrotesque-regular-webfont.eot?#iefix') format('embedded-opentype'),
       url('../fonts/brandon-grotesque/brandongrotesque-regular-webfont.woff') format('woff'),
       url('../fonts/brandon-grotesque/brandongrotesque-regular-webfont.ttf') format('truetype'),
       url('../fonts/brandon-grotesque/brandongrotesque-regular-webfont.svg#webfont') format('svg');
}


h1, h2, h3, h4, h5, h6 {
  @include special-title();
}

body {
  font-family: 'BrandonGrotesque Regular';
  font-size: 16px;
  line-height: 1.4;
}

a {

}

img {
  max-width: 100%;
  height: auto;
}

p {
  margin: 0px 0px 10px 0px;
}
