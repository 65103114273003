// =================================== TEXT
h1, h2, h3, h4, h5, h6 {
  color: $color-brown;
}

// =================================== BREADCRUMB
.breadcrumb {
  color: $color-brown;

  a {
    color: $color-brown;
  }

  .last {
    &, a {
      color: $color-gray-1;
    }
  }
}

// =================================== FORMS
input.form-text,
input.form-email,
input.form-password,
select, textarea {
  border-color: $color-gray-1;

  &.error {
    border-color: $color-red;
  }
}

.form-item {
  .description {
    color: $color-gray-2;
  }
}

fieldset {
  legend {
    color: $color-gray-2;
  }
}

// =================================== HEADER
.l-header {
  background: white;
}

.block--system-main-menu .menu {
  a {
    color: $color-brown;
    text-transform: uppercase;
    font-size: 0.95em;
  }
}

.block--views-cheeses-dropdown {
  .menu-dropdown > ul {
    background-color: white;

    a {
      color: $color-brown;
      text-transform: uppercase;
      font-size: 0.95em;
    }
  }
}

.l-top {
  border-color: $color-light-gray;
}

// =================================== FOOTER
.l-footer {
  background: #111111 url(../images/bg-tartan-red.jpg) repeat-x;
  color: white;

  text-transform: uppercase;
  font-size: 0.8em;
}


.block--menu-menu-footer .menu {
  a {
    color: white;
  }
}

// =================================== SLIDESHOW
.slideshow-controls-buttons {
  .slideshow-prev, .slideshow-next {
    color: white;
    text-shadow: 0px 0px 5px rgba(black, 0.5);
  }
}

// =================================== GENERAL
.page-title {
  &:after {
    border-color: $color-red;
  }
}

// =================================== NAVIGATION
.navigation-button {
  color: $color-gray-2;
}

.l-mobile {
  color: $color-light-gray;

  .menu {
    a {
      color: $color-brown;
    }
  }
}

// =================================== HOMEPAGE
.page-home {
  .pane-bean-welcome {
    .field--name-field-block-body {
      color: $color-gray-2;
    }
  }

  .pane-recipes-homepage {
    .pane-title {
      background-color: white;
    }

    .slideshow-controls-buttons {
      .slideshow-prev, .slideshow-next {
        color: $color-red;
      }
    }
  }

  .pane-newsletter {
    background: transparent url(../images/newsletter-bg.jpg) center center;
    background-size: cover;
    background-attachment: fixed;

    .label {
      color: white;
    }
  }
}

// =================================== CHEESES
.node-type-cheese {
  .pane-node-field-cheese-quote {
    background-color: $color-light-gray;
    color: $color-brown;

    &:after {
      color: $color-red;
    }
  }

  .pane-cheese-health {
    border-color: $color-red;

    > .pane-title {
      &, span {
        color: $color-brown;
      }
    }
  }
}

.page-node-14, .page-node-52, .page-node-59 {
  .l-footer {
    background-image: url(../images/bg-tartan-blue.jpg);
  }
}

.page-node-4 {
  .l-footer {
    background-image: url(../images/bg-tartan-green.jpg);
  }
}

// =================================== RECIPES
.node--recipe {
  .recipe-details {
    border-color: $color-light-gray;
    color: $color-brown;
  }

  .recipe-ingredients {
    ul > li {
      &:before {
        color: $color-red;
      }
    }
  }

  .recipe-instructions {
    ul > li {
      &:before {
        color: $color-red;
        font-weight: bold;
        font-size: 1.25em;
      }
    }
  }
}

ul.recipes, ul.recipes-carousel {
  .recipe {
    .recipe-title {
      a {
        background-color: $color-light-gray;
        color: $color-brown;
      }
    }
  }
}

.recipe-filter {
  .recipe-filter-button {
    background-color: white;
  }

  .recipe-filter-form {
    label {
      color: $color-brown;
    }
  }

  &.open {
    background-color: white;
    border-color: $color-red;

    .button-filter {
      background-color: transparent;
      color: $color-red;
      border-color: $color-red;
    }
  }
}

.recipe-filter-overlay {
  background-color: rgba(black, 0.5);
}

// =================================== NEWS
.view-news.view-display-id-page {
  .views-row {
    border-color: $color-light-gray;

    .post-date {
      .month { color: $color-gray-1; }
      .day { color: $color-gray-2; }
    }

    h2 {
      &, span, a {
        color: $color-brown;
      }
    }

    .views-field-field-news-categories {
      color: $color-red;
    }
  }
}

.pane-views-exp-news-page {
  label {
    color: $color-brown;
    border-color: $color-light-gray;
  }

  .form-type-bef-link {
    a {
      border-color: $color-light-gray;

      &.active {

      }
    }
  }
}

.node-type-news {
  .pane-node-created {
    color: $color-gray-2;

    &:after {
      color: $color-gray-1;
    }
  }

  .pane-node-field-news-categories {
    color: $color-red;
  }

  .pane-news-recent {
    ul {
      li {
        border-color: $color-light-gray;
      }

      a {
        color: $color-brown;
      }
    }
  }
}

// =================================== CONTACT
.page-contact {
  form {
    background-color: $color-light-gray;
  }
}

// =================================== RECIPE FORM
.nazareth-recipe-form {
  fieldset {
    background-color: $color-light-gray;
  }
}


// =================================== FOR PROFESSIONALS
.nazareth-pro-information-form {
  .form {
    background-color: $color-light-gray;
  }
}

.view-id-cheeses.view-display-id-professional_list {
  .field--name-title, .field--name-field-cheese-package-title {
    color: $color-brown;
  }

  .cheese-packages {
    > li > span {
      border-color: $color-light-gray;
      color: $color-red;
    }

    > li.active, > li:hover {
      > span {
        background-color: $color-red;
      }
    }
  }
}

.block--menu-menu-professional {
  background-color: white;

  li:after {
    color: $color-brown;
  }

  a {
    color: $color-brown;
  }
}
