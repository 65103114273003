// =================================== BASIC LAYOUT COMPONENTS
.element-invisible {
  @include element-invisible();
}

h1.site-name {
  @include element-invisible();
}

body ul.tabs {
  @include clearfix;
  clear: both;
}

// =================================== FORM
.field-location {
  @include row();

  .form-item-postal {
    @include span-columns(3);
  }

  .form-item-city {
    @include span-columns(9);
  }

  @include media($l-bp-mobile) {
    .form-item-postal, .form-item-city {
      @include span-columns(12);
    }
  }
}

.field-address {
  @include row();

  .form-item-address-street {
    @include span-columns(9);
  }

  .form-item-address-street-nr {
    @include span-columns(3);
  }

  @include media($l-bp-mobile) {
    .form-item-address-street, .form-item-address-street-nr {
      @include span-columns(12);
    }
  }
}


// =================================== BASE
a {
  color: $color-red;
}

@include media($l-bp-tablet) {
  #admin-menu {
    display: none;
  }

  html body.admin-menu {
    margin-top: 0 !important;
  }
}


// =================================== HOMEPAGE
.page-home {
  .pane-bean-welcome {
    @include content-width();
    padding: 4em;
    line-height: 1.1;
    text-align: center;
  }

  .home-center-row {
    @include content-width();
    padding-top: 4em;
  }

  .home-bottom-row {
    @include content-width();
    @include row();
    padding-top: 4em;

    .left, .right {
      @include span-columns(6);
    }

    @include media($l-bp-tablet) {
      .left, .right {
        @include span-columns(12);
      }
    }
  }

  .pane-recipes-homepage {
    @include site-width();
    position: relative;

    .pane-title {
      position: absolute;
      z-index: 100;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin: 0;
      padding: 1em;
      text-align: center;
      font-size: 2em;

      .word {
        display: block;
      }

      .first-word {
        line-height: 1;
        border-bottom: 1px solid;
      }
    }

    .slideshow-pager {
      display: none;
    }

    .slideshow-controls-buttons {
      .slideshow-prev, .slideshow-next {
        > span {
          display: none;
        }

        position: absolute;
        z-index: 200;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .slideshow-prev {
        margin-left: -4.25em;
      }

      .slideshow-next {
        margin-left: 4.25em;
      }
    }

    @include media($l-bp-mobile) {
      .pane-title {
        position: static;
        transform: none;
        padding: 0.5em;
      }

      .slideshow-controls-buttons {
        .slideshow-prev, .slideshow-next {
          top: 10%;
          transform: none;
          margin: 0;
        }

        .slideshow-prev {
          left: 10%;
        }

        .slideshow-next {
          left: auto;
          right: 10%;
        }
      }
    }
  }

  .pane-newsletter {
    @include site-width();

    form {
      @include content-width();
      @include row();

      .label {
        @include span-columns(6 of 24);
        padding: 0.9em 0;
      }

      input {
        @include span-columns(6 of 24);
      }

      button {
        @include span-columns(6 of 24);
      }
    }

    @include media($l-bp-tablet) {
      form {
        .label, input, button {
          @include span-columns(12);
          margin-bottom: 1em;
        }
      }
    }
  }
}

// =================================== HEADER
.l-header {
  @include content-width;
  @include row();

  .l-branding {
    @include span-columns(2);
    padding-top: 10px;
  }

  .l-region--navigation {
    @include span-columns(10);

    .block--system-main-menu {
      @include span-columns(11);
    }

    .block--locale-language {
      @include span-columns(1);
    }

    h2 {
      display: none;
    }
  }
  .navigation-button {
    position: absolute;
    top: 1.5em;
    display: none;
  }

  @include media($l-bp-tablet-landscape) {
    .l-branding, .l-region--navigation {
      @include span-columns(12);
      text-align: center;

      .block--system-main-menu {
        @include span-columns(12);
        @include omega();
      }

      .block--locale-language {
        position: absolute;
        top: 15px;
      }
    }
  }

  @include media($l-bp-tablet) {
    .l-region--navigation {
      display: none;
    }

    .navigation-button {
      display: block;
    }

    .l-branding {
      padding-top: 10px;
      padding-bottom: 10px;
    }

  }
}

.l-top {
  border-top: 1px solid;
  position: relative;
  z-index: 0;
}

// =================================== FOOTER
.l-footer {
  padding-top: 53px;
  margin-top: 50px;

  .l-region--footer {
    @include content-width;
    padding-top: 15px;
    padding-bottom: 15px;

    > .region-content {
      @include row();
      display: table;
      width: 100%;
    }

    @include display-context(table) {
      .block--nazareth-site-nazareth-copyright {
        @include span-columns(4);
      }

      .block--menu-menu-footer {
        @include span-columns(4);
        text-align: center;

        h2 {
          display: none;
        }
      }

      .block--starss-logo {
        @include span-columns(4);
        text-align: right;
      }
    }
  }

  @include media($l-bp-tablet) {
    .l-region--footer {
      .block--nazareth-site-nazareth-copyright {
        @include span-columns(6);
      }

      .block--menu-menu-footer {
        @include span-columns(6);
        @include omega();
        text-align: right;
      }

      .block--starss-logo {
        @include span-columns(12);
        margin-top: 10px;
      }
    }
  }

  @include media($l-bp-mobile) {
    .l-region--footer {
      .block {
        @include span-columns(12);
        margin-top: 0.5em;
        margin-bottom: 0.5em;
        text-align: left;
      }
    }
  }
}

// =================================== MEDIA
.media-gallery {
  img {
    width: 100%;
  }

  .field-items {
    @include row();

    .field-item {
      @include span-columns(3 of 6);
      @include omega(2n);
      margin-bottom: 0.5em;
    }
  }
}

.media-gallery-small {
  img {
    width: 100%;
  }

  .field-items {
    @include row();

    .field-item {
      @include span-columns(4);
      @include omega(3n);
      margin-bottom: 0.5em;
    }
  }
}

// =================================== CONTENT
.not-front .l-main {
  @include content-width;
}

.not-front .l-content {
  position: relative;
}

.node-type-recipe, .node-type-cheese {
  .page-title {
    @include span-columns(7 of 10);
  }

  .panel-2col {
    @include row;

    .panel-col-first {
      @include span-columns(7 of 10);
    }

    .panel-col-last {
      @include span-columns(3 of 10);
      margin-top: -130px;
    }
  }

  @include media($l-bp-tablet) {
    .page-title {
      @include span-columns(12);
    }

    .panel-2col {
      .panel-col-first {
        @include span-columns(12);
      }

      .panel-col-last {
        @include span-columns(12);
        margin-top: 1em;
      }
    }
  }
}


.node--recipe {
  @include row();

  .recipe-ingredients {
    @include span-columns(5);
  }

  .recipe-instructions {
    @include span-columns(7);
  }
}

ul.recipes {
  @include list-clear;
  @include row();

  .recipe {
    @include span-columns(3);
    @include omega(4n);

    margin-bottom: 1.5em;
    overflow: hidden;
  }

  &.recipes-related {
    .recipe {
      @include span-columns(6);
      @include omega(2n);

      margin-bottom: 0.5em;
    }
  }

  @include media($l-bp-tablet) {
    .recipe {
      @include span-columns(6);
      @include omega(2n);
    }
  }

  @include media($l-bp-mobile) {
    .recipe {
      @include span-columns(12);
      @include omega();
    }
  }
}

ul.recipes-carousel {
  @include list-clear;
  @include row();
}

.page-news {
  .panel-2col {
    @include row();

    .panel-col-first {
      @include span-columns(9);
    }

    .panel-col-last {
      @include span-columns(3);
    }
  }

  .view-news {
    .views-row {
      @include row();
      padding-bottom: 2em;
      margin-bottom: 2em;

      .post-date {
        float: left;
        width: 100px;

        span {
          display: block;
          text-align: center;
        }
      }

      > h2, > .views-field {
        margin-left: 100px;
      }

      > h2 {
        margin-top: 0;
        margin-bottom: 0.15em;
      }
    }
  }

  @include media($l-bp-tablet) {
    .panel-2col {
      .panel-col-first, .panel-col-last {
        @include span-columns(12);
      }
    }

    .view-news {
      .views-row {
        .post-date {
          float: none;
          margin-bottom: 1em;

          span {
            display: inline;
          }
        }

        > h2, > .views-field {
          margin-left: 0;
        }
      }
    }
  }
}

.node-type-news {
  .panel-2col {
    @include row();

    .panel-col-first {
      @include span-columns(9);
    }

    .panel-col-last {
      @include span-columns(3);
    }
  }

  @include media($l-bp-tablet) {
    .panel-2col {
      .panel-col-first, .panel-col-last {
        @include span-columns(12);
        margin-bottom: 1em;
      }
    }
  }
}

.node-type-page {
  .panel-2col {
    @include row();

    .panel-col-first {
      @include span-columns(8);
    }

    .panel-col-last {
      @include span-columns(4);
    }
  }

  @include media($l-bp-tablet) {
    .panel-2col {
      .panel-col-first, .panel-col-last {
        @include span-columns(12);
        margin-bottom: 1em;
      }
    }
  }
}

.page-contact {
  .panel-2col {
    @include row();

    .panel-col-first {
      @include span-columns(6);
    }

    .panel-col-last {
      @include span-columns(6);
    }
  }

  .contact-address {
    @include row();
    margin-bottom: 1em;

    .left, .right {
      @include span-columns(6);
    }
  }

  @include media($l-bp-tablet) {
    .panel-2col {
      .panel-col-first, .panel-col-last {
        @include span-columns(12);
        margin-bottom: 1em;
      }
    }
  }
}

.bean-block {
  @include row();

  .group-block-left, .group-block-right {
    @include span-columns(6);
  }

  @include media($l-bp-tablet) {
    .group-block-left, .group-block-right {
      @include span-columns(12);
      margin-bottom: 1em;
    }
  }
}

// =================================== RECIPE FORM
.nazareth-recipe-form {
  .recipe {
    @include row();

    .left, .right {
      @include span-columns(6);
    }

    .right {
      @include omega();
    }

    .bottom {
      @include span-columns(12);
    }

    @include media($l-bp-tablet) {
      .left, .right {
        @include span-columns(12);
        margin-bottom: 1em;
      }
    }

    .form-item-recipe-difficulty {
      label {
        display: block;
      }

      .stars {
        font-size: 2em;
        display: inline-block;
      }
    }
  }

  #edit-recipe-preparation {
    height: 30em;
  }
}


// =================================== FOR PROFESSIONALS
.professionals-cheeses {
  .paragraphs-item-cheese-package {
    .content {
      @include row();

      .group-left {
        @include span-columns(2);
        text-align: center;
      }

      .group-right {
        @include span-columns(10);
      }

      @include media($l-bp-tablet-landscape) {
        .group-left {
          @include span-columns(4);
        }

        .group-right {
          @include span-columns(8);
        }
      }

      @include media($l-bp-tablet) {
        .group-left {
          @include span-columns(5);
        }

        .group-right {
          @include span-columns(7);
        }
      }

      @include media($l-bp-mobile) {
        .group-left, .group-right {
          @include span-columns(12);
        }
      }
    }
  }
}

.nazareth-pro-information-form {
  @include row();

  .left, .right {
    @include span-columns(6);
  }

  .products-sample-information {
    .product {
      @include row();
      margin-bottom: 1em;

      .field-preview {
        @include span-columns(5);
      }

      .field-title, .field-options {
        @include span-columns(7);
        @include omega();
      }
    }
  }

  @include media($l-bp-tablet) {
    .left, .right {
      @include span-columns(12);
      margin-bottom: 1em;
    }
  }

  @include media($l-bp-mobile) {
    .products-sample-information {
      .product {
        .field-preview, .field-title, .field-options {
          @include span-columns(12);
        }
      }
    }
  }
}

.block--menu-menu-professional {
  position: absolute;
  top: 55px;
  right: 0;
  padding: 20px;

  @include media($l-bp-tablet-landscape) {
    position: static;
    padding: 0;
    margin: 10px 0;
  }
}
